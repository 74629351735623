
window.cp = window.cp || {};

window.cp.Loader = function(is3Ds = false) {
    let is3DS = false;

    const eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    let ifrm = null;

    function embedIFrame(params) {
        const frontUrl = process.env.FRONTEND_URL + '/index' + (is3DS ? '_3ds' : '') + '.html';

        ifrm = document.createElement('iframe');
        let paramsString = Object.entries(params).map(([key, val]) => `${key}=${encodeURIComponent(val)}`).join('&');

        ifrm.setAttribute('src', frontUrl + '?' + paramsString);

        ifrm.style.cssText = 'height: 100% !important;width: 100% !important;position: fixed !important;' +
            'z-index: 9999 !important;border: 0 !important; top: 0 !important;bottom: 0 !important;left: 0 !important;' +
            'right: 0px !important;max-height: 100% !important;';

        document.body.appendChild(ifrm);
    }

    function initWidget(params, secureCheck = false) {
        is3DS = secureCheck;

        embedIFrame(params);
        listener(params);
    }

    function listener (params) {
        eventer(messageEvent, function (e) {
            // TODO check: if (e.origin !== 'my.domain') return;

            const data = e.data;

            console.log(data);

            switch (data.id) {
                case 'cp.pay.success':
                    closeWidget();

                    if (typeof params.onSuccess === 'function') {
                        params.onSuccess(data);
                    }

                    break;

                case 'cp.pay.error':
                    closeWidget();

                    if (typeof params.onError === 'function') {
                        params.onError(data);
                    }

                    break;

                case 'cp.pay_3ds.success': // from 2nd iframe
                    closeWidget();

                    if (typeof params.onSuccess === 'function') {
                        params.onSuccess(data);
                    }

                    break;

                case 'cp.pay_3ds.error': // from 2nd iframe
                    closeWidget();

                    if (typeof params.onError === 'function') {
                        params.onError(data);
                    }

                    break;

                case 'cp.closeIFrame':
                    closeWidget();

                    break;
                default:
                    break;
            }
        });
    }

    function closeWidget() {
        if (!ifrm) {
            return;
        }

        ifrm.remove();
        ifrm = null;
    }

    return {
        initWidget: initWidget,
    };
}();
